



























































































import axios from "axios";
import APP_CONFIG from "@/apps/core/modules/config";
import {
  defineComponent,
  onMounted,
  ref,
} from "@vue/composition-api";
import useFormModel from "@/apps/core/modules/useFormModel";
import { SetoranMandiriFM } from "@/apps/tabungan/models/setoranMandiriForm";
import { SetoranMandiri } from "../models/setoranMandiri";
import { listVM as setoranMandiriListVM } from "@/apps/tabungan/modules/stores/setoranMandiri";
import { listVM as setoranListVM } from "@/apps/tabungan/modules/stores/setoran";
import { listVM as pinjamanListVM } from "@/apps/pinjaman/modules/stores/pengajuan";
import UploadPhoto from "@/apps/core/modules/uploadPhoto";

/* Hanya create saja, tanpa updated, tidak perlu paramsId dari router */
export default defineComponent({
  name: "SetoranPelunasanForm",
  components: {
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const requiredFields = ["bukti"];
    const nonRequiredFields = ["catatan"];
    const form = new SetoranMandiriFM(requiredFields, nonRequiredFields);
    const composition = useFormModel(form);
    const setoran = composition.instance as SetoranMandiri;
    const buktiDataRef = ref(null);
    const pelunasanUrl = `${APP_CONFIG.baseAPIURL}/tabungan/pelunasan/`;
    const namaPinjaman = ref("");

    onMounted(async () => {
      axios.get(pelunasanUrl).then((response) => {
        if (response.data.data.length > 0) {
          Object.assign(setoran, response.data.data[0]);
          if (setoran.pinjaman instanceof Object && setoran.pinjaman.produk) {
            namaPinjaman.value = setoran.pinjaman.produk.nama ;
          }
        }
      });
    });

    const saveSetoran = async (url: string | null) => {
      setoran.bukti = url;
      form.api.createUrl = pelunasanUrl;
      const pinjaman = setoran.pinjaman;
      if (setoran.pinjaman instanceof Object) {
        setoran.pinjaman = setoran.pinjaman.id;
      }
      await form.save();
      if (!form.errorMap.nonFieldErrors) {
        setoranMandiriListVM.setRefreshRequest("setoranMandiri");
        setoranListVM.setRefreshRequest("setoran");
        pinjamanListVM.setRefreshRequest("pinjaman");
        setoran.pinjaman = pinjaman;
      }
    };

    const saveForm = async () => {
      const uploadPhoto = new UploadPhoto("setoran", (uploading: boolean) => {
        form.isLoading = uploading;
      });
      uploadPhoto.requestAWS(buktiDataRef.value, saveSetoran);
    };

    const validateBukti = () => {
      setoran.bukti = buktiDataRef.value;
      form.validate("bukti");
    };

    return {
      // Data
      buktiDataRef,
      namaPinjaman,

      // Composition
      ...composition,

      // Method
      saveForm,
      validateBukti,
    };
  },
});
