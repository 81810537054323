import FormModel from "@/apps/core/models/formModel";
import { SetoranMandiri, SetoranMandiriAPI } from "./setoranMandiri";
import { snakeCase } from "lodash";

class SetoranMandiriFM extends FormModel {
  constructor(requiredFields: Array<string>, nonRequiredFields: Array<string>) {
    super(
      new SetoranMandiriAPI(),
      new SetoranMandiri(),
      requiredFields,
      nonRequiredFields
    );
  }

  getPayload(): Record<string, any> {
    // digunakan untuk mendapatkan model object yang berisi data
    // untuk dikirimkan ke API
    const payload: Record<string, any> = {};
    const initStateRec = this.initState as Record<string, any>;
    for (const [fieldName, value] of Object.entries(this.instance)) {
      const initVal = initStateRec[fieldName];
      // hanya mengambil data yang berubah saja
      if (initVal !== value) {
        // convert to snake_case
        const sField = snakeCase(fieldName);
        payload[sField] = value;
      }
    }
    return payload;
  }
}


export { SetoranMandiriFM };
